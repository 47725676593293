<template>
  <v-container>
    <v-row>
      <v-col
        ><h3>{{ $t("silos.predictions.title") }}</h3></v-col
      >
      <v-col>
        <v-chip color="gray" @click="setDate(undefined)" text
          >{{ $t("silos.predictions.non") }}
        </v-chip>
      </v-col>
      <v-col>
        <v-chip color="primary" @click="setDate(3)" text
          >{{ $t("silos.predictions.3d") }}
        </v-chip>
      </v-col>
      <v-col>
        <v-chip color="primary" @click="setDate(5)" text
          >{{ $t("silos.predictions.5d") }}
        </v-chip>
      </v-col>
      <v-col>
        <v-chip color="primary" @click="setDate(10)" text
          >{{ $t("silos.predictions.10d") }}
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
  },

  data() {
    return { dialog: false };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },

  methods: {
    setDate(days) {
      if (days === undefined) {
        this.localValue = undefined;
        return;
      }

      this.localValue = this.addHours(-(days * 24), new Date());
    },
  },
};
</script>
