<template>
  <v-card :style="`min-height: ${height}px`">
    <v-row no-gutters style="height: 90%">
      <e-chart
        v-if="opts.series.length > 0"
        :option="opts"
        :style="`width: 100%; max-width: 100%; max-height: 100%; min-height:${height}px;height:${height}px`"
        :autoresize="true"
      />
    </v-row>
  </v-card>
</template>

<script>
import "echarts/lib/coord/cartesian/Grid";

export default {
  props: {
    series: {
      default: function () {
        return [];
      },
    },

    headers: {
      default: function () {
        return [];
      },
    },

    fetchFunction: {
      default: () => {},
    },

    height: {
      default: 300,
    },

    contentWeight: {
      default: null,
    },

    totalVolume: {
      default: undefined,
    },
  },

  data() {
    return {
      startDate: null,
      endDate: null,

      opts: {
        tooltip: {
          trigger: "axis",
          valueFormatter: (value) =>
            this.contentWeight == null
              ? value.toFixed(1) + "m³"
              : value.toFixed(1) +
                "m³ / " +
                (value * this.contentWeight).toFixed(1) +
                "ton" +
                (this.totalVolume !== undefined
                  ? " / " + parseInt((value / this.totalVolume) * 100) + "%"
                  : ""),
          axisPointer: {
            type: "cross",
          },
        },

        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
        },

        yAxis: [],
        series: [],
      },
    };
  },

  methods: {
    setSeries(s) {
      let yAxises = [];
      for (var x of this.headers) {
        yAxises.push({
          type: "value",
          name: x,
        });
      }

      this.opts.yAxis = yAxises;

      let i = 0;
      this.opts.series = [];
      this.$nextTick(() => {
        for (let serie of s) {
          this.opts.series.push({
            name: this.headers[i],
            type: "line",
            data: serie,
            areaStyle: { opacity: 0.35 },
            yAxisesIndex: i,
          });

          i++;
        }
      });
    },
  },

  created() {
    if (this.series.length > 0) {
      this.setSeries(this.series);
    }
  },

  watch: {
    series(val) {
      this.setSeries(val);
    },
  },
};
</script>
