<template>
  <div class="pa-0">
    <div class="pa-4 d-flex justify-space-around align-center">
      <v-chip class="mx-4" color="primary" @click="day(1)"
        >{{ $t("dates.1days") }}
      </v-chip>
      <v-chip class="mx-4" color="primary" @click="day(7)"
        >{{ $t("dates.7days") }}
      </v-chip>
      <v-chip class="mx-4" color="primary" @click="day(30)"
        >{{ $t("dates.30days") }}
      </v-chip>

      <slim-date-picker v-model="fromDate" :label="$t('dates.fromDate')" />
      <slim-date-picker v-model="toDate" :label="$t('dates.toDate')" />
    </div>
    <prediction-date-picker v-model="predictFrom" />

    <div class="d-flex">
      <div style="width: 100%; height: 100%">
        <v-container class="ma-0" fluid>
          <v-row>
            <v-col md="8" sm="12">
              <simplechart
                :series="series"
                :fetch-function="fetchFunction"
                :headers="headers"
                :height="300"
                :total-volume="silo.property.totalVolumeMRounded"
                :content-weight="
                  silo.contents != null
                    ? silo.contents.weightByCubicMeter
                    : null
                "
              />
            </v-col>
            <v-col md="4" sm="12">
              <v-row>
                <v-col md="6" sm="6">
                  <div class="info-chip bad-background">
                    <span> {{ $t("silos.dashboard.lastVolume") }} </span>
                    <span v-if="silo.contents == null"
                      >{{ lastValueVolume.toFixed(1) }}m³
                    </span>
                    <span v-else
                      >{{ lastValueVolume.toFixed(1) }}m³ /
                      {{
                        (
                          lastValueVolume * silo.contents.weightByCubicMeter
                        ).toFixed(1)
                      }}ton</span
                    >
                  </div>
                </v-col>
                <v-col md="6" sm="6">
                  <div class="info-chip good-background">
                    <span> {{ $t("silos.dashboard.lastPercentile") }} </span>
                    {{ lastValuePrecentile }}%
                  </div>
                </v-col>

                <v-col md="6" sm="6">
                  <div class="info-chip neutral-background">
                    <span>
                      {{ $t("silos.dashboard.totalVolumeProperty") }}
                    </span>
                    <span v-if="silo.contents == null"
                      >{{
                        silo.property.totalVolumeMRounded.toFixed(1)
                      }}m³</span
                    >
                    <span v-else
                      >{{ silo.property.totalVolumeMRounded.toFixed(1) }}m³ /
                      {{
                        (
                          silo.property.totalVolumeMRounded *
                          silo.contents.weightByCubicMeter
                        ).toFixed(1)
                      }}ton</span
                    >
                  </div>
                </v-col>
                <v-col md="6" sm="6">
                  <div class="info-chip neutral-background">
                    <span> {{ $t("silos.dashboard.changeLast24") }} </span>
                    <span v-if="silo.contents == null"
                      >{{ changeValue }}m³</span
                    >
                    <span v-else>
                      {{ changeValue }}m³ /
                      {{
                        (
                          changeValue * silo.contents.weightByCubicMeter
                        ).toFixed(1)
                      }}ton</span
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="d-flex">
            <div v-if="!hideMap" class="pr-3">
              <gmap-map
                :center="center"
                :zoom="7"
                map-type-id="terrain"
                style="width: 500px; min-height: 1200px; max-width: 100%"
              >
                <gmap-marker
                  :position="pos"
                  @click="center = pos"
                  :icon="{
                    url: 'icons/silo-marker.png',
                    scaledSize: { height: 40, width: 40 },
                  }"
                />
              </gmap-map>
            </div>
            <v-row>
              <v-col md="12" sm="12">
                <silo-alarm-active-display :siloId="siloId" />
              </v-col>
              <v-col md="12" sm="12">
                <alarm-silo-list :siloId="siloId" />
              </v-col>
              <v-col md="12" sm="12">
                <alarm-histories-list :siloId="siloId" />
              </v-col>
              <v-col md="7" sm="7">
                <silo-event-display :siloId="siloId" />
              </v-col>
              <v-col md="5" sm="5">
                <meta-display :silo="silo" />
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AlarmHistoriesList from "../alarm/AlarmHistoriesList.vue";
import AlarmSiloList from "../alarm/AlarmSiloList.vue";
import MetaDisplay from "../silos/MetaDisplay.vue";
import SiloAlarmActiveDisplay from "./SiloAlarmActiveDisplay.vue";
import SiloEventDisplay from "./SiloEventDisplay.vue";
import Simplechart from "../chart/Simplechart.vue";
import SlimDatePicker from "../common/SlimDatePicker.vue";
import siloDataRepository from "@/api/repositories/siloDataRepository";
import PredictionDatePicker from "@/components/silos/PredictionDatePicker.vue";

export default {
  name: "SiloListCompactData",

  components: {
    PredictionDatePicker,
    AlarmHistoriesList,
    AlarmSiloList,
    MetaDisplay,
    SiloAlarmActiveDisplay,
    SiloEventDisplay,
    Simplechart,
    SlimDatePicker,
  },

  props: {
    siloId: { default: null },
    hideMap: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    let temp = this.addHours(-24 * 7);
    let fromDate = this.humanDate(temp, "yyyy-MM-dd");
    let toDate = this.humanDate(new Date(), "yyyy-MM-dd");

    return {
      silo: {},
      headers: [],
      series: [],
      lastValueVolume: 0.0,
      lastValuePrecentile: 0,
      changeValue: 0.0,

      from: temp,
      to: new Date(),

      predictFrom: undefined,

      fromDate: fromDate,
      toDate: toDate,

      center: {
        lat: 59.27412,
        lng: 15.2066,
      },
      pos: {
        lat: 59.28878,
        lng: 15.22509,
      },
    };
  },

  computed: {
    ...mapGetters("silos", ["getSiloById"]),
  },

  methods: {
    ...mapActions("siloData", ["getSiloData"]),

    async day(days) {
      if (days === undefined || days <= 0) return;

      this.from = this.humanDate(this.addHours(-24 * days), "yyyy-MM-dd");
      this.to = this.humanDate(this.addHours(24), "yyyy-MM-dd");

      this.fromDate = this.from;
      this.toDate = this.humanDate(new Date(), "yyyy-MM-dd");
    },

    async fetchFunction(from, to) {
      let _from = from;
      let _to = to;

      if (typeof from == "object") {
        _from = from.toISOString();
      }

      if (typeof to == "object") {
        _to = to.toISOString();
      }

      let _predictFrom = this.predictFrom;
      if (typeof this.predictFrom == "object")
        _predictFrom = this.predictFrom.toISOString();

      let datas = await this.getSiloData({
        siloId: this.siloId,
        from: _from,
        to: _to,
      });
      if (datas.length <= 0) {
        this.headers = [];
        this.series = [];
        this.lastValueVolume = 0.0;
        this.lastValuePrecentile = 0;
        this.changeValue = 0.0;
        return;
      }
      this.headers = [this.silo.name + " - Volym kvar"];
      this.series = [
        datas.map((d) => {
          return [new Date(d.date), d.volumeLeftM];
        }),
      ];

      this.lastValueVolume = datas[datas.length - 1].volumeLeftM;
      this.changeValue = parseFloat(
        datas[datas.length - 1].volumeLeftM - datas[0].volumeLeftM
      ).toFixed(1);
      this.lastValuePrecentile = parseInt(
        (datas[datas.length - 1].volumeLeftM /
          this.silo.property.totalVolumeMRounded) *
          100
      );

      // Check if we should predict here
      if (_predictFrom == undefined && typeof _predictFrom != "string") return;

      let predictions = await siloDataRepository
        .getSiloDataPrediction(this.siloId, _predictFrom)
        .then((d) => d.data)
        .catch((d) => d);
      if (
        typeof predictions == "string" ||
        predictions == undefined ||
        predictions?.length <= 0
      )
        return;

      this.headers.push(this.silo.name + " - Uppskattad förbrukning");
      this.series.push(
        predictions.map((d) => {
          return [new Date(d.date), d.volumeLeftM];
        })
      );
    },
  },

  async created() {
    this.silo = this.getSiloById(this.siloId);
    this.pos = {
      lat: this.silo.lat,
      lng: this.silo.lng,
    };
    this.center = this.pos;

    await this.fetchFunction(this.from, this.to);
  },

  watch: {
    async siloId() {
      // Fetch the information regarding the silo that has been activated
      this.silo = this.getSiloById(this.siloId);
      this.pos = {
        lat: this.silo.lat,
        lng: this.silo.lng,
      };
      this.center = this.pos;

      await this.fetchFunction(this.from, this.to);
    },

    async fromDate(val) {
      this.from = val;

      await this.fetchFunction(this.from, this.to);
    },

    async toDate(val) {
      this.to = val;

      await this.fetchFunction(this.from, this.to);
    },

    async predictFrom() {
      await this.fetchFunction(this.from, this.to);
    },
  },
};
</script>

<style lang="scss">
.info-chip {
  width: 100%;
  height: 140px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  font-weight: bolder;
  flex-direction: column;

  span {
    font-size: 1.3rem;
  }
}

.neutral-background {
  border: 4px solid #00008b24;
  border-radius: 10px;
  background: #00008b1c;
}

.good-background {
  border: 4px solid #1bff0038;
  border-radius: 10px;
  background: #1bff001f;
}

.bad-background {
  border: 4px solid #f11c1252;
  border-radius: 10px;
  background: #f11c1208;
}
</style>
