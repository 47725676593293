<template>
  <div>
    <h2>{{ $t("alarms.title") }}</h2>
    <v-divider />

    <alarm-list no-fetch />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AlarmList from "./AlarmList.vue";

export default {
  components: {
    AlarmList,
  },

  props: {
    siloId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions("alarms", ["getAlarmsBySilo"]),
  },

  async created() {
    await this.getAlarmsBySilo(this.siloId);
  },

  watch: {
    async siloId(val) {
      await this.getAlarmsBySilo(val);
    },
  },
};
</script>