<template>
  <div :class="'circle-indicator ' + getColor"></div>
</template>

<script>
export default {
  name: "CircleIndicator",

  props: {
    status: {
      type: Boolean,
      default: false,
    },

    latestDataDate: {
      type: String,
      default: null,
    },

    // idx = 0 == true idx = 1 == false
    colors: {
      type: Array,
      default: () => ["green lighten-2", "red lighten-2", "grey lighten-2"],
    },
  },

  computed: {
    isOlder() {
      if (this.latestDataDate == null) return false;

      var targetDate = this.addHours(-24);
      var date = new Date(this.latestDataDate);

      return date <= targetDate;
    },

    getColor() {
      if (this.isOlder) {
        // If the device is on alarm but also has not been communicating during the last 24 hours
        if (this.status) return "inactive-and-alarm";
        return this.colors[2];
      }

      return this.status ? this.colors[1] : this.colors[0];
    },
  },
};
</script>

<style lang="sass">
.circle-indicator
  width: 35px
  height: 35px
  border-radius: 100%

.inactive-and-alarm
  background: linear-gradient(to left, rgba(244,244,244,1) 50%, rgba(229, 115, 115, 1) 50%)
</style>