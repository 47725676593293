<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="localDate"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formatedDate"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="localDate" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(localDate)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "SlimDatePicker",

  props: {
    date: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "Datum",
    },
  },

  model: {
    prop: "date",
  },

  data() {
    return {
      menu: false,
    };
  },

  computed: {
    formatedDate() {
      return this.humanDate(this.localDate, "yyyy-MM-dd");
    },

    localDate: {
      get() {
        return this.date;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>