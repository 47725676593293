<template>
  <div>
    <h2>{{ $t("alarms.actives.historiesTitle") }}</h2>
    <v-divider />

    <v-data-table :headers="headers" :items="histories" :items-per-page="5">
      <template v-slot:[`item.siloId`]="{ item }">
        {{
          getSiloById(item.siloId).name == undefined
            ? "N/A"
            : getSiloById(item.siloId).name
        }}
      </template>

      <template v-slot:[`item.alarmId`]="{ item }">
        {{
          getAlarmById(item.alarmId).name == undefined
            ? "N/A"
            : getAlarmById(item.alarmId).name
        }}
      </template>

      <template v-slot:[`item.type`]="{ item }">
        {{
          $t(
            `alarms.types.${
              getAlarmById(item.alarmId).type == undefined
                ? 0
                : getAlarmById(item.alarmId).type
            }`
          )
        }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ humanDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.removedAt`]="{ item }">
        {{ humanDate(item.removedAt) }}
      </template>
      <template v-slot:[`item.ackUser`]="{ item }">
        {{ item.user ? item.user.email : "System" }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AlarmHistoriesList",

  props: {
    siloId: {
      type: String || null,
      default: null,
    },
  },

  data() {
    return {
      headers: [
        { text: this.$t("alarms.actives.fields.alarmId"), value: "alarmId" },
        { text: this.$t("alarms.actives.fields.siloId"), value: "siloId" },
        { text: this.$t("alarms.fields.type"), value: "type" },
        { text: this.$t("alarms.actives.fields.message"), value: "message" },
        {
          text: this.$t("alarms.actives.fields.removedAt"),
          value: "removedAt",
        },
        {
          text: this.$t("alarms.actives.fields.ackUser"),
          value: "ackUser",
        },
        {
          text: this.$t("alarms.actives.fields.createdAt"),
          value: "createdAt",
        },
      ],
      histories: [],
    };
  },

  computed: {
    ...mapGetters("silos", ["getSiloById"]),
    ...mapGetters("alarms", ["getAlarmById"]),
  },

  methods: {
    ...mapActions("alarms", ["getHistoriesBySilo", "getAlarms"]),
  },

  async created() {
    if (this.siloId != null)
      this.histories = await this.getHistoriesBySilo(this.siloId);
  },

  watch: {
    async siloId(val) {
      if (val != null) this.histories = await this.getHistoriesBySilo(val);
    },
  },
};
</script>