<template>
  <div>
    <h2>{{ $t("silos.eventTitle") }}</h2>
    <v-divider></v-divider>
    <div
      class="d-flex justify-center align-center"
      v-if="permitted('Silos.View')"
    >
      <v-select
        v-model="eventType"
        :items="eventTypes"
        item-text="name"
        item-value="value"
        prepend-inner-icon="mdi-format-list-bulleted-type"
        style="max-width: 230px"
      ></v-select>

      <v-text-field
        v-model="eventMessage"
        prepend-inner-icon="mdi-calendar-plus"
        :label="$t('silos.eventMessage')"
      />

      <v-btn @click="addEvent" text color="primary">{{
        $t("common.save")
      }}</v-btn>
    </div>

    <v-timeline
      align-top
      dense
      style="max-height: 350px; overflow-y: scroll; overflow-x: hidden"
    >
      <!-- Yellow for information red for error -->
      <v-timeline-item
        :color="getColorFromEventType(event.type)"
        :key="i"
        v-for="(event, i) in events"
      >
        <v-row class="pt-1">
          <v-col cols="3">
            <strong>{{ humanDate(event.createdAt) }}</strong>
          </v-col>
          <v-col>
            <div class="text-caption">
              {{ event.message }}
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SiloEventDisplay",

  props: {
    siloId: {
      default: null,
    },
  },

  data() {
    return {
      eventType: 2,
      eventMessage: "",
      eventTypes: [
        { name: this.$t("silos.events.2"), value: 2 },
        { name: this.$t("silos.events.3"), value: 3 },
      ],
      events: [],
    };
  },

  methods: {
    ...mapActions("silos", ["getEvents", "createEvent"]),
    getColorFromEventType(type) {
      switch (type) {
        case 0:
          return "yellow";
        case 1:
          return "error";
        case 2:
          return "primary";
        case 3:
          return "green";
        case 4:
          return "orange";
        default:
          return "blue";
      }
    },

    async addEvent() {
      await this.createEvent({
        siloId: this.siloId,
        payload: { siloEventType: this.eventType, message: this.eventMessage },
      });
      this.events = await this.getEvents({
        siloId: this.siloId,
        count: 25,
        page: 0,
      });
      this.eventMessage = "";
    },
  },

  async created() {
    if (this.siloId != null)
      this.events = await this.getEvents({
        siloId: this.siloId,
        count: 25,
        page: 0,
      });
  },

  watch: {
    async siloId(val) {
      if (val != null)
        this.events = await this.getEvents({ siloId: val, count: 25, page: 0 });
    },
  },
};
</script>