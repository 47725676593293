<template>
  <div>
    <h2>{{ $t("alarms.actives.title") }}</h2>

    <v-divider />

    <div
      v-if="getActiveAlarmBySilo(siloId).length == 0"
      class="d-flex align-center justify-center ma-4"
    >
      <h2 class="overline">
        {{
          isSuperAdmin()
            ? $t("alarms.actives.noAlarmActivesSuperAdmin")
            : $t("alarms.actives.noAlarmActives")
        }}
      </h2>
    </div>
    <div v-else>
      <div
        class="d-flex justify-center align-center mx-4 my-2"
        v-for="alarm in getActiveAlarmBySilo(siloId)"
        :key="alarm.alarmActiveId"
      >
        <span class="mx-4">
          <circle-indicator :status="true" />
        </span>
        <div
          class="d-flex justify-space-between align-center"
          style="width: 90%"
        >
          <span class="overline">{{
            getAlarmById(alarm.alarmId)
              ? getAlarmById(alarm.alarmId).name
              : "Alarm"
          }}</span>
          <span>{{ humanDate(alarm.createdAt) }}</span>
          <span>
            <confirm-dialog
              :title="$t('alarms.actives.acknowledge')"
              :description="$t('alarms.actives.acknowledgeDescription')"
              v-on:ok="acknowledge(alarm.alarmId, alarm.siloId)"
              :icon="true"
              icon-value="mdi-check-circle"
              v-if="permitted('Alarms.Acknowledge')"
            >
            </confirm-dialog>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CircleIndicator from "../common/CircleIndicator.vue";

export default {
  name: "SiloAlarmActiveDisplay",

  components: {
    CircleIndicator,
  },

  props: {
    siloId: {
      type: String || null,
      default: null,
    },
  },

  data() {
    return {
      data: null,
    };
  },

  methods: {
    ...mapActions("alarms", ["acknowledgeAlarm", "getAlarmActivesBySilo"]),
    ...mapActions("silos", ["getEvents"]),

    async acknowledge(alarmId, siloId) {
      await this.acknowledgeAlarm({ alarmId: alarmId, siloId: siloId });
      await this.getAlarmActivesBySilo(this.siloId);
      await this.getEvents({ siloId: this.siloId, count: 25, page: 0 });
    },
  },

  computed: {
    ...mapGetters("alarms", ["getActiveAlarmBySilo", "getAlarmById"]),
  },

  async created() {
    await this.getAlarmActivesBySilo(this.siloId);
  },

  watch: {
    async siloId() {
      await this.getAlarmActivesBySilo(this.siloId);
    },
  },
};
</script>